<template>
    <div id="ap">
        <template v-if="mode == 'form'">
            <form-design v-model="formData" @success="getList()" @close="closeForm"></form-design>
        </template>
        <template v-if="mode == 'list'">
            <a-layout>
                <div class="content-header">
                    <a-affix :offset-top="0" style="width: 100%;">
                        <a-row type="flex" justify="space-between">
                            <a-col span="12">
                                <common-page-btn @save="createForm()">
                                    <template v-if="mode == 'form'">
                                        <a-button @click="() => { mode = 'list'; }">取消保存</a-button>
                                    </template>
                                </common-page-btn>
                            </a-col>
                            <a-col span="12">
                                <a-row type="flex" align="middle" justify="end" :gutter="3">
                                    <a-col span="12">
                                        <a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
                                                 @on-clear="getList" placeholder="关键词查询"></a-input>
                                    </a-col>
                                    <!-- <a-col span="6" v-if="getOrganId() == 0">
                                        <a-select v-model="param.organId" style="width:100%" @change="doRefresh"
                                                  placeholder="根据单位筛选">
                                            <a-select-option :value="organ.organId" v-for="(organ, index) in getOrganList()"
                                                             :key="index">{{ organ.name }}</a-select-option>
                                        </a-select>
                                    </a-col> -->
                                </a-row>
                            </a-col>
                        </a-row>
                    </a-affix>
                </div>
            </a-layout>
            <a-layout>
                <a-layout-content>
                    <a-table :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
                             :style="{ background: '#FFF' }" size="small" row-key="formId" border :columns="keys" ref="list"
                             :loading="loading" :data-source="data.records">

                        <!--操作-->
                        <template slot-scope="row" slot="action">
                            <a-button-group shape="circle" size="small">
                                <a-tooltip title="编辑"><a-button type="link" v-has="'approve:form:save'" icon="edit"
                                              @click="() => { formData = row; mode = 'form'; }"></a-button></a-tooltip>
                                <a-tooltip title="删除"><a-button type="link" v-has="'approve:form:del'" icon="delete"
                                              @click="del(row.formId)"></a-button></a-tooltip>
                            </a-button-group>
                        </template>
                    </a-table>
                </a-layout-content>
            </a-layout>
            <a-row>
                <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
                    <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
                                  @showSizeChange="setLimit" :current="data.current" />
                </a-col>
            </a-row>
            <a-back-top />
        </template>


        <a-modal v-model="showName" title="创建表单" :width="400" @ok="doCreate()" @cancel="() => { formData.name = ''; }"
                 centered destroyOnClose>
            <a-form-model ref="formRef" :model="formData">
                <a-form-model-item prop="name" required>
                    <a-input v-model="formData.name" placeholder="请输入名称"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

    </div>
</template>

<script>
import utils from '@/common/utils';
import formDesign from './components/form-design.vue';
import apiUtil from '@/common/apiUtil';
export default {
    components: { formDesign },
    data() {
        return {
            loading: false,
            mode: 'list',//list=列表页面,form=设计页面
            data: [],
            param: {
                page: 1,
                limit: 20,
                organId: apiUtil.getDefaultOrganId(),
                keyword: ''
            },
            keys: [
                { title: 'ID', dataIndex: 'formId', width: 100, align: 'center' },
                { title: '名称', dataIndex: 'name', align: 'left', width: 200, ellipsis: true },
                { title: '使用流程', dataIndex: 'approveName', width: 400, ellipsis: true },
                { title: '创建人', dataIndex: 'staffName', width: 100, ellipsis: true },
                { title: '创建时间', dataIndex: 'createTime', width: 160, ellipsis: true },
                { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
            ],
            showName: false,
            formData: {}
        };
    },
    created() {
        this.getList();
    },
    methods: {
        ...apiUtil,
        closeForm() {
            this.mode = 'list';
            this.getList();
        },
        // 创建表单
        createForm() {
            this.formData = {
                formId: '',
                name: '',
                content: "2333",
                organId: this.param.organId
            };
            this.showName = true;
        },
        doCreate() {
            if (utils.isEmpty(this.formData.name)) {
                utils.error('请输入表单名称'); return;
            }
            this.showName = false;
            this.mode = 'form';
        },
        //删除
        del(id) {
            this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
                utils.showSpin();
                this.http.delete('/platform/approve/form/del/' + id).then(ret => {
                    if (ret.code == 200) {
                        this.utils.success('操作成功！').then(() => {
                            this.getList();
                        });
                    }
                });
            });
        },
        //获取表单数据
        doRefresh() {
            this.param.page = 1;
            this.getList();
        },
        // 获取数据
        getList() {
            this.loading = true;
            this.http
                .get('/platform/approve/form/list', this.param)
                .then(ret => {
                    this.data = ret.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        //设置每页的显示条数
        setLimit(current, limit) {
            this.param.page = 1;
            this.param.limit = limit;
            this.getList();
        },
        //页码切换
        getNext(page) {
            this.param.page = page;
            this.getList();
        }
    }
};
</script>

<style scoped></style>
